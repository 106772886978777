import * as messageStatuses from '@/data/message-statuses';

export function messageStatusLabelClass(status) {
  switch (status) {
    case messageStatuses.SENT: return 'label-success';
    case messageStatuses.DELIVERED: return 'label-success';
    case messageStatuses.READ: return 'label-success';
    case messageStatuses.FAILED: return 'label-error';
    case messageStatuses.UNDELIVERED: return 'label-error';
    case messageStatuses.SENDING: return 'label-warning';
    case messageStatuses.QUEUED: return 'label-warning';
    case messageStatuses.RECEIVED: return 'label-info';
    default: return '';
  }
}

export default {
  messageStatusLabelClass,
};
